import HomePage from 'components/pages/HomePage';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { setCookie } from 'cookies-next';
import { getPartners } from 'services/partnersService';
import { getAmbassadors } from 'services/ambassadorsService';
import { getGeoInfo } from 'services/modalCountryService';
import { getNewsBackAdmin } from 'services/newsService';
import { getProducts } from 'services/productPageService';
import { currentGeo } from 'helpers/hooks/defaultCountryId';


export const getServerSideProps = async props => {
  const req = props.req,
    res = props.res;

  if (
    !props.req.cookies.NEXT_LOCALE ||
    props.req.cookies.NEXT_LOCALE !== props.locale
  ) {
    setCookie('NEXT_LOCALE', props.locale, {
      req,
      res,
      maxAge: 60 * 60 * 24 * 365,
    });
  }

  const partnersItemsResponse = await getPartners({
    locale: props.locale,
    geo: await currentGeo(props),
  });
  const ambassadorsItemsResponse = await getAmbassadors({
    locale: props.locale,
    geo: await currentGeo(props),
  });

  const newsItemsResponse = await getNewsBackAdmin({
    locale: props.locale,
    geo: await currentGeo(props)
  })

  const productsResponse = await getProducts({
    locale: props.locale,
    show_on_main_page: true,
    geo: await currentGeo(props),
  });

  return {
    props: {
      partnersData: partnersItemsResponse?.data?.partners,
      ambassadorsData: ambassadorsItemsResponse?.data?.ambassadors,
      newsData: newsItemsResponse?.data?.news,
      productsData: productsResponse?.data,
      ...(await serverSideTranslations(props.locale, [
        'common',
        'header',
        'footer',
        'homepage',
        'events',
        'partners',
        'media',
        'modal',
        'feedback',
        'modal',
        'howItWorks',
        'rewards',
        'reviews',
        'faq',
        'ambassadors',
        'products',
        'news',
        'blog',
      ])),
    },
  };
};

export default HomePage;
